import React from 'react'

import { GetStaticProps, GetStaticPaths } from 'next'

import { getPageData, getPageSettings, getRoutes } from '../utils/api'
import { PageData, PageProps } from '../@types/Page'
import { PageSettings } from '../@types/PageSettings'

import Layout from '../src/layouts/Layout'

import Content from '../src/components/Content'

const Slug: React.FC<PageProps> = ({ settings, content }) => (
  <Layout settings={settings} content={content}>
    <Content settings={settings} content={content} />
  </Layout>
)

export const getStaticProps: GetStaticProps = async (context) => {
  const slug: string[] = (context?.params?.slug as string[]) || []

  const settings: PageSettings = await getPageSettings()

  try {
    const content: PageData = await getPageData(slug)

    const data = {
      settings,
      content,
    }

    return {
      props: { ...data }, // will be passed to the page component as props
      revalidate: 10, // 10 secodns  // 3600, // 1 hour
    }
  } catch (err) {
    return {
      props: {
        settings,
      },
      notFound: true,
    }
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const routes = await getRoutes()

  const paths = routes.routes.map((route) => ({ params: { slug: [route] } }))

  return { paths, fallback: 'blocking' }
}

export default Slug
